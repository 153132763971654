import React from 'react'

import PageWithLeftMenu from '../../components/PageWithLeftMenu'
import P from '../../components/P'
import SEO from '../../components/seo'

import items from './_items'

export default () => {
  return (
    <PageWithLeftMenu title="R.E.B.E" items={items}>
      <SEO
        title="REBE - sistem cultul baptist"
        keywords={[`rebe`, 'baptist', `fundația ebe`]}
      />
      <P style={{ textAlign: 'center' }}>
        <P>Sistemul de Educație Biblică prin Extensie</P>
        <P>Coordonator sistem R.E.B.E.</P>
        <P>
          <i>Adi Paul</i>
        </P>
      </P>

      <P>
        <b>Viziunea R.E.B.E.</b>
      </P>

      <P>
        Un sistem național de încredere în rândul păstorilor și lucrătorilor
        baptiști pentru păstrarea, dezvoltarea, promovarea și implementarea
        educației biblice prin extensie în cadrul Uniunii Baptiste în țară și
        peste granițe.
      </P>
      <P>
        <b>Scopul</b>
      </P>
      <P>
        Promovarea și coordonarea implementării filosofiei de educație biblică
        prin extensie prin biserica locală în Romînia și peste graniță pentru
        multiplicarea ucenicilor Domnului Isus, conform textului din 2 Tim 2:2
      </P>

      <P>
        <b>Misiunea</b>
      </P>
      <P>
        Sprijinirea bisericilor din România în lucrarea de echipare
        transformatoare, folosind uneltele Educației Biblice prin Extensie, de
        către păstori și slujitori în bisericile locale . Ev. Matei 9:35-38,
        28:18-20, Fapte 1:8.
      </P>

      <P>
        <b>Nevoia</b>
      </P>
      <P ml={4}>
        <ul>
          <li>
            {' '}
            Sistemele de pregătire a slujitorilor pentru lucrarea în biserică
            sunt destul de limitate ca și număr.
          </li>
          <li>
            După absolvirea seminarului biblic sau a unei școli teologice,
            absolvenții au nevoie de un sistem local și național prin care să
            continue propria echipare.
          </li>
          <li>
            Sunt tot mai puțini lucrători echipați intenționat în biserică spre
            lărgirea Împărăției lui Dumnezeu.
          </li>
        </ul>
      </P>
      <P>
        <b>Strategii</b>
      </P>
      <P ml={4}>
        <ol>
          <li> Contextualizarea curricumului în limba română.</li>
          <li>
            {' '}
            Implementarea uceniciei ca și mod de viață în biserica locală.
          </li>
          <li>
            {' '}
            Dezvoltarea unei rețele eficientă printre credincioșii baptiști din
            Romînia și din afara granițelor.
          </li>
          <li>
            Redescoperirea păstorilor și misionarilor care au fost implicați în
            echiparea prin filosofia de ucenicie EBE dar care nu s-au
            multiplicat, pentru a veni în sprijinul lor în lucrarea de echipare
            a liderilor slujitori.
          </li>
          <li>
            Descoperirea și colaborarea cu pastori și laici (potențiali și
            interesați) deschiși pentru echiparea prin filosofia de ucenicizare
            prin uneltele REBE.
          </li>
        </ol>
      </P>

      <P>
        <b>Obiective</b>
      </P>
      <P ml={4}>
        <ol>
          <li>
            REBE România dorește să extindă echiparea păstorilor și lucrătorilor
            în bisericile baptiste. 2 Tim 2:2
          </li>
          <li> Consolidarea nucleului național REBE lărgit.</li>

          <li>
            Zidirea spirituală a echipei pornind de la testul Right Path
            personal și cel de lucru în echipă.
          </li>
          <li>
            Promovarea filosofiei de lucrare REBE la nivelul Comunităților și a
            Uniunii bisericilor Baptiste
          </li>
        </ol>
      </P>

      <P>
        <b>Evaluarea</b>
      </P>
      <P>
        Evaluarea va fi un proces care se va face pe tot parcursul lucrării dar
        în mod specific, rezultatele evaluării se vor prezenta la întîlnirea
        bordului în fiecare an. Echipa de coordonare analizează slujirea și
        prezintă nucleului REBE la consultarea anuală, concluziile împreună cu
        raportul financiar.
      </P>
      <P>Ovidiu Ghiță - Asistent Rețea REBE - partea de EST</P>
      <P>Eugen V Paul - Asistent Rețea REBE - partea de VEST</P>
    </PageWithLeftMenu>
  )
}
